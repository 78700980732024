import React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";

import SEO from "../components/SEO";

import RequestReminder from "../sections/RequestReminder";

const AuctionReminderTemplate: React.FC<
  PageProps<Queries.AuctionReminderTemplateQuery>
> = ({ data: { auction } }) => {
  const title = `Request Reminder | ${auction.name}`;

  // ModalRoutingContext allows us to check if the current url is
  // coming from a Modal trigger. If not, we render a normal page.
  // This is used for visitors coming directly to an assemblage
  // listing (ie. from a search engine) where a modal wouldn't trigger.
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          <SEO
            title={title}
            description={`Register for an SMS reminder from CLHBid.com for ${auction.name}`}
          />
          {modal ? (
            <RequestReminder auction={auction} closeTo={closeTo} />
          ) : (
            <div className="flex items-center justify-center p-4">
              <div className="w-fit max-w-full mt-8 mb-16 mx-auto rounded-lg shadow-md overflow-hidden">
                <RequestReminder auction={auction} closeTo={closeTo} />
                <p className="px-4 mb-0 pb-4">
                  <Link to="/#current-auctions">
                    « Return to Current Auctions
                  </Link>
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export const query = graphql`
  query AuctionReminderTemplate($url: String) {
    auction(url: { eq: $url }) {
      name
      url
      description
      auctionDate
    }
  }
`;

export default AuctionReminderTemplate;
