import React from "react";
import { HiXCircle } from "react-icons/hi";

const colors = {
  success: "bg-green-500",
  error: "bg-red-500",
};

const Message: React.FC<{
  text: string;
  type: "success" | "error";
  onClose?: () => void;
}> = ({ text, type, onClose }) => {
  return (
    text && (
      <p
        className={`flex text-md italic mt-0 mb-6 text-white rounded-sm px-2 ${colors[type]}`}
      >
        <span className="grow py-2">{text}</span>
        {onClose && (
          <button className="shrink-0" onClick={onClose}>
            <HiXCircle className="w-6 h-6" />
          </button>
        )}
      </p>
    )
  );
};

export default Message;
