export function isEmailAddress(value: string): boolean | string {
  return (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    ) || "Please enter a valid Email Address"
  );
}

export function isPhoneNumber(value: string): boolean | string {
  return (
    value.replace(/\D/g, "").length === 10 || "Phone number must be 10 digits"
  );
}
