import React, { Ref } from "react";
import { ChangeHandler, FieldErrors } from "react-hook-form";

const classes = {
  text: "w-full rounded-md bg-gray-100 !border-clhbid-orange focus:outline-none focus:shadow-buttonFocus",
  label: "block text-xs font-bold uppercase mb-2",
};

// This disable is reuqired because our version of eslint-plugin-react
// has a bug causing react/prop-types to error when using React.forwardRef.
// See https://github.com/yannickcr/eslint-plugin-react/issues/3140
/* eslint-disable react/prop-types */
const Input = React.forwardRef<
  HTMLElement,
  {
    name: string;
    type: "text" | "tel" | "email" | "checkbox" | "textarea";
    label: React.ReactNode;
    rows?: number;
    errors?: FieldErrors;
    placeholder?: string;
    onBlur: ChangeHandler;
    onChange: ChangeHandler;
    className?: string;
  }
>(
  (
    {
      name,
      type,
      label,
      rows,
      errors,
      placeholder,
      onBlur,
      onChange,
      className,
    },
    ref
  ) => {
    const message = errors?.[name]?.message;
    const margin = message ? "mb-2" : "mb-6";

    const error = <p className="text-sm italic mb-0 text-red-500">{message}</p>;

    if (type === "checkbox") {
      return (
        <div className={className}>
          <label className={`block text-gray-500 font-bold ${margin}`}>
            <input
              ref={ref as unknown as Ref<HTMLInputElement>}
              name={name}
              id={`${name}Input`}
              type={type}
              onBlur={onBlur}
              onChange={onChange}
              className="mr-2 leading-tight"
            />
            <span className="text-sm">{label}</span>
          </label>
          {error}
        </div>
      );
    }

    if (type === "textarea") {
      return (
        <div className={className}>
          <label className={classes.label} htmlFor={`${name}Input`}>
            {label}
          </label>
          <textarea
            ref={ref as unknown as Ref<HTMLTextAreaElement>}
            name={name}
            id={`${name}Input`}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            rows={rows}
            className={`${classes.text} ${margin}`}
          />
          {error}
        </div>
      );
    }

    return (
      <div className={className}>
        <label className={classes.label} htmlFor={`${name}Input`}>
          {label}
        </label>
        <input
          ref={ref as unknown as Ref<HTMLInputElement>}
          name={name}
          id={`${name}Input`}
          type={type}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          className={`${classes.text} ${margin}`}
        />
        {error}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
