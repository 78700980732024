import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { useForm } from "react-hook-form";
import axios from "axios";

import { isEmailAddress, isPhoneNumber } from "../helpers/validators";

import Input from "../components/Input";
import Button from "../components/Button";
import Message from "../components/Message";

type Inputs = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  consent: boolean;
};

type MessageState = {
  type: "error" | "success";
  text: string;
};

export const onSubmit = async (
  data: Inputs,
  auction: Pick<Queries.Auction, "name" | "url">
): Promise<void> => {
  await axios({
    method: "post",
    url: "/api/request-reminder",
    data: {
      first_name: data.firstName,
      last_name: data.lastName,
      auction_url: auction.url,
      email_address: data.emailAddress,
      phone_number: `+1${data.phoneNumber.replace(/\D/g, "")}`,
    },
  })
    .then(() => {
      toast(`Successfully scheduled reminder for ${auction.name}!`, {
        type: "success",
      });
    })
    .catch((err) => {
      Sentry.captureException(err);
      throw err;
    });
};

const RequestReminder: React.FC<{
  closeTo: string | null;
  auction: Pick<Queries.Auction, "name" | "url">;
}> = ({ auction, closeTo }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageState>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    window?.posthog?.capture("AuctionReminder.opened", {
      $set: { auction: auction },
    });
  }, [auction]);

  return (
    <div className="w-[30rem] max-w-full bg-white">
      <h3 className="text-center px-12 py-2 mb-2 text-2xl bg-clhbid-orange">
        {auction.name}
      </h3>
      <p className="mt-4 mb-2 px-4">
        Please fill out the form below to register for an SMS reminder from
        CLHBid.com on the day of the auction.
      </p>
      <form
        className="p-4"
        autoComplete="true"
        name="Request Reminder"
        onSubmit={handleSubmit(async (data) => {
          setLoading(true);

          await onSubmit(data, auction)
            .then(() => {
              window?.posthog?.capture("AuctionReminder.sent", {
                $set: { auction: auction },
              });

              navigate(closeTo || "/");
            })
            .catch(() => {
              setMessage({
                type: "error",
                text: "An error occured. Try again or contact info@clhbid.com.",
              });
            });

          setLoading(false);
        })}
      >
        {message && <Message type={message.type} text={message.text} />}
        <Input
          type="text"
          label="First Name"
          placeholder="John"
          errors={errors}
          {...register("firstName", { required: "First Name is required" })}
        />
        <Input
          type="text"
          label="Last Name"
          placeholder="Doe"
          errors={errors}
          {...register("lastName", { required: "Last Name is required" })}
        />
        <Input
          type="email"
          label="Email Address"
          placeholder="jdoe@gmail.com"
          errors={errors}
          {...register("emailAddress", {
            required: "Email Address is required",
            validate: {
              isEmailAddress,
            },
          })}
        />
        <Input
          type="tel"
          label="Phone Number (10 digits)"
          placeholder="1234567890"
          errors={errors}
          {...register("phoneNumber", {
            required: "Phone number is required",
            validate: {
              isPhoneNumber,
            },
          })}
        />
        <Input
          type="checkbox"
          label="I consent to be contacted by CLHBid.com"
          errors={errors}
          {...register("consent", {
            value: false,
            required: "Consent is required",
          })}
        />
        <Button className="w-full" size="small" type="submit" loading={loading}>
          Schedule Reminder
        </Button>
      </form>
    </div>
  );
};

export default RequestReminder;
